
.is-child .landing-footer {
  margin-top:0;
}

.is-child #page {
  padding-bottom: 60px;
}

.is-child #page .lead> a {
  color: #666562;
  font-style: italic;
}

.is-child .landing-footer {
  border-top:60px solid #EDF1ED;
}

.is-child.is-front .ajax-link-wrap a {
  bottom:-60px;
  background: #91bad4;
}

.accordion-title .title:after {
  content:none;
}


@media print {
  .top-bar.sticky {
    max-width: none !important;
    width:100% !important;
    position: absolute !important;
    left:0 !important;
    right: 0 !important;
    top:-100px !important;

  }

  .large-offset-2 {
    margin-left: 0;
  }


}
